import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material';
import './Dashboad.css'
import axios from 'axios';
import { Search } from '@mui/icons-material';

function Dashboad() {
    const [tripData, setTripData] = useState({})
    const [userData, setUserData] = useState({})
    // Month
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())
    const [selectedMonthlyRevenue, setSelectedMonthlyRevenue] = useState('')

    useEffect(() => {
        fetchTripsData();
        fetchUserData();
    }, [])

    const fetchTripsData = async () => {
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/trip`)
            .then(res => {
                if (res.data.success) {
                    setTripData(res.data.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const fetchUserData = async () => {
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/usercount`)
            .then(res => {
                if (res.data.success) {
                    setUserData(res.data.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getSubscriptionRevenue = async () => {
        const body = { from_date: fromDate, to_date: toDate }

        axios.post(`${process.env.REACT_APP_BASEURL}/admin/subscriptions-revenue`, body)
            .then(res => {
                if (res.data.success) {
                    setSelectedMonthlyRevenue(res.data.revenue)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div id='dashboard' >
            <section className='dashboard_cont'>
                <div className="dashboard_tripData_cont">
                    <h4>Trip Data</h4>
                    <div className='dashboard_tripData_cont_data'>
                        <div>
                            <p>Trip Count: <span>{tripData?.trip_count}</span></p>
                        </div>
                        <div>
                            <p>Trip Profit: <span>$ {tripData?.total_profit}</span></p>
                        </div>
                        <div>
                            <p>Total Miles Covered: <span>{tripData?.total_miles_covered} Miles</span></p>
                        </div>
                        <div>
                            <p>Profit Per User: <span>$ {tripData?.Avg_profit_per_user}</span></p>
                        </div>
                    </div>
                </div>

                <div className="dashboard_tripData_cont">
                    <h4>User Data</h4>
                    <div className='dashboard_tripData_cont_data'>
                        <div>
                            <p>Number Of Registration: <span>{userData?.user_count}</span></p>
                        </div>
                        <div>
                            <p>Average Truck Per User: <span>{userData?.avgtruckperuser}</span></p>
                        </div>
                        <div>
                            <p>Number of Documents: <span>{userData?.document_upload}</span></p>
                        </div>
                        <div>
                            <p>Truck Per State: <span>{userData?.avgtruckperstate}</span></p>
                        </div>
                        <div>
                            <p>User Per State: <span>{userData?.user_per_state}</span></p>
                        </div>
                    </div>
                </div>

                <div className="dashboard_tripData_cont">
                    <h4>Subscription Revenue</h4>
                    <p>Total Revenue: $ {selectedMonthlyRevenue}</p>

                    <div className='dashboard_FromToDate_cont' >
                        <div>
                            <h5>From Date: </h5>
                            <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                        </div>
                        <div>
                            <h5>To Date: </h5>
                            <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                        </div>

                        <IconButton onClick={getSubscriptionRevenue} sx={{ color: '#00235F', alignSelf: 'end' }}>
                            <Search />
                        </IconButton>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Dashboad