import React, { useState } from 'react'
import './Login.css';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// ICONs & SVGs
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();

        if (process.env.REACT_APP_ADMIN_LOGIN == email && process.env.REACT_APP_ADMIN_PASSWORD == password) {
            sessionStorage.setItem("My_Truck_Profit_Admin", JSON.stringify({ loggedIn: true }));
            navigate('/dashboad', { replace: true });
        } else {
            console.log('Invalid Credentials !!!');
        }
    }

    return (
        <div id='login'>
            <form className='login_form' onSubmit={handleSubmit} >
                <TextField value={email} onChange={(e) => setEmail(e.target.value)} type='email'
                    fullWidth sx={{ marginBottom: 2 }} label="Email" variant="outlined"
                />

                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

                <Button size='large' variant='contained' sx={{ backgroundColor: '#00235F !important', marginTop: 3.5 }} type='submit' fullWidth >
                    Login
                </Button>
            </form>
        </div>
    )
}

export default Login