import './App.css'
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from 'react';
// Components
import SideNav from "./Components/SideNav/SideNav";
// Pages
import Login from './Pages/Login/Login';
import Dashboad from "./Pages/Dashboad/Dashboad";
import Notification from "./Pages/Notification/Notification";

function App() {
  const location = useLocation();

  return (
    <div className="app">
      <SideNav />

      <section className='app_routes_cont' style={{ marginLeft: location.pathname == '/' ? 0 : 290 }} >
        <Routes>
          {JSON?.parse(sessionStorage.getItem('My_Truck_Profit_Admin'))?.loggedIn ? (
            <>
              <Route path="/dashboad" element={<Dashboad />} />
              <Route path="/notification" element={<Notification />} />
            </>
          ) : (
            <Route path="/" element={<Login />} />
          )}
        </Routes>
      </section>
    </div>
  );
}

export default App;
