import React, { useEffect, useState } from 'react'
import '../Dashboad/Dashboad.css'
import './Notification.css'
import axios from 'axios'
import { Alert, Button, CircularProgress, IconButton, MenuItem, TextField } from '@mui/material'
// ICONs & SVGs
import DeleteIcon from '@mui/icons-material/Delete';

function Notification() {
    const [loading, setLoading] = useState(false);
    const [getNotificationType, setGetNotificationType] = useState('all')
    const [allNotifications, setAllNotifications] = useState([])
    // Add
    const [notificationType, setNotificationType] = useState('all')
    const [notificationHeader, setNotificationHeader] = useState('')
    const [notificationData, setNotificationData] = useState('')
    const [addNotificationSuccess, setAddNotificationSuccess] = useState(false);
    const [addNotificationError, setAddNotificationError] = useState(false);
    const [addNotificationLoading, setAddNotificationLoading] = useState(false);
    // Delete
    const [deleteNotificationLoading, setDeleteNotificationLoading] = useState(false);
    const [deleteNotificationID, setDeleteNotificationID] = useState('')

    useEffect(() => {
        getAllNotifications('all');
    }, [])

    const getAllNotifications = async (val) => {
        const body = { user_type: val == 'all' ? '' : val };
        setGetNotificationType(val || 'all');
        setLoading(true);

        await axios.post(`${process.env.REACT_APP_BASEURL}/admin/notification/get`, body)
            .then(res => {
                if (res.data.success) {
                    setAllNotifications(res.data.data);
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }

    const onAddNotification = async (e) => {
        e.preventDefault();
        if (!notificationHeader || !notificationHeader || !notificationData) return;
        setAddNotificationLoading(true);

        const body = {
            user_type: notificationType,
            notification_data: { header: notificationHeader, data: notificationData }
        }

        await axios.post(`${process.env.REACT_APP_BASEURL}/admin/notification/create`, body)
            .then(res => {
                if (res.data.success) {
                    setNotificationType('all');
                    setNotificationHeader('');
                    setNotificationData('');
                    setAddNotificationSuccess(true);

                    setAllNotifications([
                        ...allNotifications,
                        {
                            ...body,
                            notification_data: JSON.stringify(body.notification_data)
                        }
                    ])

                    setTimeout(() => setAddNotificationSuccess(false), 5000);
                }
                setAddNotificationLoading(false);
            })
            .catch(err => {
                console.log(err);
                setAddNotificationLoading(false);
                setAddNotificationError(true);
                setTimeout(() => setAddNotificationError(false), 5000);
            })
    }

    const onNotificationDelete = async (id) => {
        if (!id) return;

        setDeleteNotificationLoading(true);
        setDeleteNotificationID(id);

        const body = { notification_id: id };
        await axios.put(`${process.env.REACT_APP_BASEURL}/admin/notification/delete`, body)
            .then(res => {
                if (res.data.success) {
                    setAllNotifications(allNotifications.filter(a => a.notification_id != id));
                }
                setDeleteNotificationLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDeleteNotificationLoading(false);
            })

    }

    return (
        <div id='notification' >
            <section className='dashboard_cont'>
                <h2>Add Notifications</h2>

                <form className='notification_form' onSubmit={onAddNotification} >
                    <TextField fullWidth select label="Select Notification Type" value={notificationType}
                        onChange={(e) => setNotificationType(e.target.value)}
                    >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='subscribed'>Subscribed Users</MenuItem>
                        <MenuItem value='unsubscribed'>Unsubscribed Users</MenuItem>
                    </TextField>

                    <TextField label="Notification Header" variant="outlined" value={notificationHeader} fullWidth
                        onChange={(e) => setNotificationHeader(e.target.value)}
                    />

                    <TextField label="Notification Data" variant="outlined" multiline value={notificationData} fullWidth
                        onChange={(e) => setNotificationData(e.target.value)} minRows={4} maxRows={7}
                    />

                    <Button variant='contained' type='submit' disabled={addNotificationLoading} >
                        {addNotificationLoading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : 'Add Notification'}
                    </Button>

                    {addNotificationError && <Alert severity="error">Something Went Wrong, Please Try Again !!!</Alert>}
                    {addNotificationSuccess && <Alert severity="success">Notification Added Successfully !!!</Alert>}
                </form>

                <div className="notification_data_cont">
                    <TextField fullWidth select label="Get Notifications By Type" value={getNotificationType}
                        onChange={(e) => getAllNotifications(e.target.value)}
                    >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='subscribed'>Subscribed Users</MenuItem>
                        <MenuItem value='unsubscribed'>Unsubscribed Users</MenuItem>
                        <MenuItem value='expired'>Expired Notifications</MenuItem>
                    </TextField>

                    {loading ? <CircularProgress size={35} /> : (
                        <>
                            {allNotifications?.length <= 0 && <p>No Notifications Found</p>}
                            {allNotifications?.map((n, i) => (
                                <div className="notification_card_cont" key={i} >
                                    <div>
                                        <h2>{JSON.parse(n?.notification_data)?.header}</h2>
                                        {getNotificationType !== 'expired' && (
                                            <IconButton aria-label="delete" color='error' disabled={deleteNotificationLoading}
                                                onClick={() => onNotificationDelete(n.notification_id)}
                                            >
                                                {deleteNotificationLoading && deleteNotificationID == n.notification_id ? (
                                                    <CircularProgress sx={{ color: '#d32f2f !important', marginTop: '0 !important' }} size={18} />
                                                ) : <DeleteIcon fontSize='small' />}
                                            </IconButton>
                                        )}
                                    </div>
                                    <p>{JSON.parse(n?.notification_data)?.data}</p>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </section>
        </div>
    )
}

export default Notification