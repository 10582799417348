import React from 'react'
import './SideNav.css'
import { Button, ListItemButton } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

function SideNav() {
    const navigate = useNavigate();
    const location = useLocation();

    const onLogout = () => {
        sessionStorage.removeItem("My_Truck_Profit_Admin");
        navigate('/', { replace: true });
    }

    if (location.pathname === '/') return;
    return (
        <div id='sidenav'>
            <section className='sidenav_cont' >
                <h3>My Truck Profit</h3>
                <div className='sidenav_listItem_cont' >
                    <div className="sidenav_listItems">
                        {sideNavArr?.map((s, i) => (
                            <ListItemButton key={i} onClick={() => navigate(s.link)}
                                className={`${location.pathname == s.link ? 'sidenav_listItem_active' : ''}`}
                            >
                                <h4>{s.heading}</h4>
                            </ListItemButton>
                        ))}
                    </div>

                    <Button fullWidth onClick={onLogout}>
                        Logout
                    </Button>
                </div>
            </section>
        </div>
    )
}

export default SideNav

const sideNavArr = [
    { heading: 'Dashboard', link: '/dashboad' },
    { heading: 'Notification', link: '/notification' }
]